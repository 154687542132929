import React from "react";
import styled from "styled-components";

import ArticleWrapper from "../../molecules/ArticleWrapper";
import WorkPanel from "../../molecules/WorkPanel";

import arch1 from "../../../assets/arch1.jpg";
import arch2 from "../../../assets/arch2.jpg";
import arch3 from "../../../assets/arch3.jpg";
import arch4 from "../../../assets/arch4.jpg";
import arch5 from "../../../assets/arch5.jpg";


const getWorks = () => {
  return [
    {
      src: arch1, 
      title:"dokkoi.dev(このサイト)", 
      span: "2019/12/20 - now", 
      member: 1, 
      role: "アーキテクチャ設計・画面設計・実装", 
      description:"自分の経歴や使えるスキルを他の人に効率良く伝える手段として、作成しました。口頭では言い漏らしてしまうこともまとめておけば安心です。", 
      ingenuity: "コスト効率を最優先としたアーキテクチャにしました。DBを使わない分の可用性は、コンポーネント外に別関数を用意することで(多少)補いました。"
    },
    {
      src: arch2, 
      title:"BlueMarlinHP( https://blue-marlin1996.com )", 
      span: "2020/3/18 - 2020/4/7", 
      member: 5, 
      role: "要件定義・WBS作成・アーキテクチャ設計・開発基盤の作成・ホスティング作業・進捗確認", 
      description:"新入生向けに、サークルの魅力をお届けするために作成しました。ほぼ未経験のメンバーでも設計やハンドリング次第ではきちんと作れるぞ！という実例を作りたかったです。",
      ingenuity: "非エンジニアでもキャッチアップできる環境を作り、ドキュメントを充実させたり積極的にサポートすることで全体の出力を高めることに全てのリソースを使いました。"
    },
    {
      src: arch3, 
      title:"(公開できません)", 
      span: "2020/3/24 - 2020/4/10", 
      member: 4, 
      role: "WBS作成・画面設計・アーキテクチャ設計・DB設計・実装・進捗確認", 
      description:"長期インターンで扱ったプロジェクトです。あるxlsxファイルとcsvファイルを入力とし、s3に元データを保存しつつ、それらの情報をdbに読み書きするというシステムです。",
      ingenuity: "権限をユーザーごとに分ける必要があったため、cognitoの認証トークンをAPIgatewayを通じてlambdaに渡し、lambdaからAuroraの権限テーブルを叩くことでアクションの実行可否を確認する仕様にしました。"
    },
    {
      src: arch4, 
      title:"(公開できません)", 
      span: "2019/8/7 - 2020/9/1, 2020/2/13 - 2020/3/6", 
      member: 5, 
      role: "WBS作成・画面設計・実装・進捗確認", 
      description:"長期インターンで扱ったプロジェクトです。ある画像をAPIで処理し、その結果をリアルタイムで別画面に表示させ続けるシステムです。",
      ingenuity: "リアルタイムでの表示と認証、ホスティングという要件を最小工数で実現するために、Amplifyを使用しました。また、別プロジェクトのCloudFormationを流用できたため、ecsを利用したエンドポイントの用意も最小の工数で行うことができました。"
    },
    {
      src: arch5, 
      title:"(公開できません)", 
      span: "2020/1/6 - 2020/2/17, 2020/3/6 - 2020/3/19", 
      member: 5, 
      role: "WBS作成・画面設計・アーキテクチャ設計・DB設計・実装・進捗確認", 
      description:"長期インターンで扱ったプロジェクトです。機械学習インスタンスをクラアントごとに作り、自由に学習したり推論したりできるサービスです。",
      ingenuity: "ほぼ同じ設定をクライアントごとに適用するので、CloudFormationを用いて繰り返しの手間を最小にしました。また、AlbのhealthcheckとSNSを用いて、インスタンスの監視を行ったり、backupを用いて、AMIの定期取得をするなど、運用を見据えた構築を行いました"
    },
  ]
};

const Works: React.FC = props => {
  const works = getWorks();
  return (
    <ArticleWrapper title="Works" name="works">
      <SubDoc>今までに参画したプロジェクトを、可能な範囲で紹介したいと思います。</SubDoc>
      <SubDoc>クリックするとモーダルが開かれる仕様となっております。</SubDoc>
      <SubDoc>一部、インターン先の会社で扱ったものについては、プロジェクト名を伏せさせいただきますのでご了承ください。</SubDoc>
      <SubDoc>また、以下のAWSサービスの実装はノウハウの蓄積のため、全てCloudFormationを用いて作成しております。</SubDoc>
      <WorkWrapper>
        {
          works.map((work, index) =>
            <WorkPanel 
              key={index}
              src={work.src}
              title={work.title}
              span={work.span}
              member={work.member}
              role={work.role}
              description={work.description}
              ingenuity={work.ingenuity}
            />
          )
        }
      </WorkWrapper>
    </ArticleWrapper>
  );
};

const WorkWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #f6f6f6;
  width: 100%;
`;

const SubDoc = styled.div`
  color: #666;
  font-size: 14px;
  text-align: center;
`;


export default Works;
